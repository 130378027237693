header {
    height: 100vh;
    padding-top: 3rem;
    overflow: hidden;
}

.header__container>h5{
    font-size: 2rem;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ======================== CTA ================*/
.cta {
    margin-top: 2.2rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ======================== HEADER SOCIALS ================*/
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/*======================== ME ===================*/


.me {
    background: linear-gradient(var(--color-primary), transparent);
    height: 33rem;
    width: 20rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 2rem 4.5rem 4.5rem 4.5rem;
}
.scroll__down {
    color: var(--color-white);
}

/*======================== SCROLL DOWN ===================*/
.scroll__down {
    position: absolute;
    right: 3.2rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 500;
    font: 1.9rem;
    color: var(--color-primary);
}

/* ======================= MEDIA QUERIES (MEDIUM DEVIVCES) =============*/
@media screen and (max-width: 1024px) {
    header {
        height: 68vvh;
        margin-bottom: -17rem;

    }

    .me{
        display: none;
    }

}


@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header__socials, .scroll__down {
        display: none;
    }
    .me{
        display: none;
    }

}
